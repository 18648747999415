.spinner {
    position: absolute;
    top: calc(68% - 3em);
    left: calc(60% - 3em);
    width: 3em;
    height: 3em;
    border: 1.1em solid rgba(0, 0, 0, 0.2);
    border-left: 1.1em solid #121214;
    border-radius: 50%;
    animation: spinner 1.5s infinite linear;
    transition: opacity 0.3s;
  }
  
  .spinner--hide {
    opacity: 0;
  }
  
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }